import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Healthy Pantry Staples For A Well-Balanced Diet"
            description="A well-balanced healthy diet is chock full of pantry staples such as fruits and vegetables, legumes, nuts and seeds, and healthy fats."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                Healthy Pantry Staples For A Well-Balanced Diet
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/Healthy-Pantry-Staples-For-A-Well-Balanced-Diet.jpg" alt="Healthy Pantry Staples For A Well-Balanced Diet"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@joniastin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Jony Ariadi</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/health?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection> 
        <Paragraph>
            The World Health Organization (WHO) <ExternalLink to = "https://www.who.int/news-room/fact-sheets/detail/healthy-diet">defines a healthy diet</ExternalLink> as one rich in fruit and vegetables. This diet should also include plenty of nuts, legumes, and whole grains, while the consumption of free sugars, fats, and sodium salt should be limited.
        </Paragraph>
        <Paragraph>
            Of course, the specifics of healthy eating (also known as 'clean eating' though that phrase is somewhat controversial) for each person depend on their age, gender, level of physical activity, lifestyle, as well as dietary norms, cultural context they live in, and similar.
        </Paragraph>
        <Paragraph>
            Is it possible to generalize a single healthy diet that fits everyone? Probably not. 
        </Paragraph>
        <Paragraph>
            But there are still some pantry foods and food products that are universally considered ‘healthy’ and that every person can benefit from.
        </Paragraph>
        <Paragraph>
            Let’s see what food <InternalLink to = "/pantry-staples-list/">staples you can include in your pantry</InternalLink> for a diverse and well-balanced diet that boosts your health.
        </Paragraph>
        <BlogHeading2>
            Fruits and vegetables
        </BlogHeading2>
        <Paragraph>
            Reasons why they’re healthy:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Fruits and veggies are important sources of vitamins (such as vitamin C and folate) and minerals (like potassium).</UnorderedListItem>
            <UnorderedListItem>They consist of a lot of <ExternalLink to = "https://www.nhs.uk/live-well/eat-well/5-a-day/why-5-a-day/">dietary fiber</ExternalLink>, a healthy ingredient which promotes digestive health, prevents constipation, and reduces your risk of bowel cancer.</UnorderedListItem>
            <UnorderedListItem>According to the <ExternalLink to = "https://www.hsph.harvard.edu/nutritionsource/what-should-you-eat/vegetables-and-fruits/">Harvard School of Public Health</ExternalLink>, a diet rich in fresh produce can lower blood pressure, reduce the risk of stroke and heart disease, type 2 diabetes, certain types of cancer, and has a positive effect on blood sugar levels.</UnorderedListItem>
            <UnorderedListItem>Additionally, focusing your diet on non-starchy fruits and vegetables (such as leafy greens, tomatoes, cucumbers, peppers, apples, pears, berries, etc.) helps promote weight loss.</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            The following healthy food options from the fruit and vegetable category can be stored in a dry, cool kitchen pantry:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Apples</UnorderedListItem>
            <UnorderedListItem>Beets</UnorderedListItem>
            <UnorderedListItem>Butternut squash</UnorderedListItem>
            <UnorderedListItem>Cabbage</UnorderedListItem>
            <UnorderedListItem>Carrots</UnorderedListItem>
            <UnorderedListItem>Canned tomatoes (without added sugar)</UnorderedListItem>
            <UnorderedListItem>Citrus fruits (lemons, oranges, tangerines, etc.)</UnorderedListItem>
            <UnorderedListItem>Pomegranates</UnorderedListItem>
            <UnorderedListItem>Rutabagas</UnorderedListItem>
            <UnorderedListItem>Spaghetti squash</UnorderedListItem>
            <UnorderedListItem>Sweet potatoes</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Legumes
        </BlogHeading2>
        <Paragraph>
            Reasons why they’re healthy:
        </Paragraph>
        <Paragraph>
            Legumes are plants (and fruits and seeds from those plants) that serve as one of the best plant-based protein options out there.
        </Paragraph>
        <Paragraph>
            They also have <ExternalLink to = "https://www.mayoclinic.org/healthy-lifestyle/nutrition-and-healthy-eating/in-depth/legumes/art-20044278">absolutely no cholesterol</ExternalLink> in them and are low in fat.
        </Paragraph>
        <Paragraph>
            What they do contain is high levels of potassium, magnesium, and folate. Potassium and magnesium promote the growth and function of our cells, nerves, and muscles, while folate has a vital role in the formation of healthy red blood cells.
        </Paragraph>
        <Paragraph>
            Dried and canned legumes also have a <InternalLink to = "/how-to-stock-a-pantry-for-a-year/">ridiculously long shelf life</InternalLink>, mostly because they come in the form of canned goods. Here are some goods for you to consider for your next healthy meal:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Canned beans or dried beans (any kind: black beans, white beans, kidney beans, etc.)</UnorderedListItem>
            <UnorderedListItem>Canned or dried chickpeas</UnorderedListItem>
            <UnorderedListItem>Canned or dried lentils</UnorderedListItem>
            <UnorderedListItem>Peanuts (and peanut butter, without added sugar)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Nuts and seeds
        </BlogHeading2>
        <Paragraph>
            Reasons why they’re healthy:
        </Paragraph>
        <Paragraph>
            Nuts and seeds are considered <ExternalLink to = "https://www.webmd.com/diet/health-benefits-nuts">superfoods</ExternalLink> because they are loaded with vitamin E, vitamin B, and others. They also contain a lot of minerals (magnesium, potassium, calcium, and phosphorus) and healthy, unsaturated fats (more on them after the jump).
        </Paragraph>
        <Paragraph>
            When consumed in limited amounts due to their high caloric value, nuts and seeds can aid in weight loss, cancer prevention, diabetes control, cholesterol control, and more. 
        </Paragraph>
        <Paragraph>
            Nuts, especially, contain high amounts of antioxidants. Antioxidants are substances that can <ExternalLink to = "https://www.nccih.nih.gov/health/antioxidants-in-depth">prevent cell damage</ExternalLink>, slow down the natural aging processes, and lower cancer risk, among other benefits.
        </Paragraph>
        <Paragraph>
            Here is a long list of nuts and seeds to keep in your kitchen:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Almonds</UnorderedListItem>
            <UnorderedListItem>Cashews</UnorderedListItem>
            <UnorderedListItem>Hazelnuts</UnorderedListItem>
            <UnorderedListItem>Macadamia nuts</UnorderedListItem>
            <UnorderedListItem>Pecans</UnorderedListItem>
            <UnorderedListItem>Pine nuts</UnorderedListItem>
            <UnorderedListItem>Pistachios</UnorderedListItem>
            <UnorderedListItem>Walnuts</UnorderedListItem>
            <UnorderedListItem>Chia seed</UnorderedListItem>
            <UnorderedListItem>Flaxseed</UnorderedListItem>
            <UnorderedListItem>Hemp seeds</UnorderedListItem>
            <UnorderedListItem>Pumpkin seeds</UnorderedListItem>
            <UnorderedListItem>Sunflower seeds</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            What about your favorite nut butter as a healthy pantry staple? Nut butters can also find their place in a healthy recipe book - just make sure you're using one that has no added sugar in it (for example, almond butter made of 100% almonds).
        </Paragraph>
        <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/post-2022-13-healthy-pantry-staples-bowl-of-oats-hazelnuts-almonds-and-raisins.png" alt="A white bowl of oats, almonds, hazelnuts, and raisins"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/ja/@margzu?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Margarita Zueva</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/grains?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading2>
            Whole grains
        </BlogHeading2>
        <Paragraph>
            Reasons why they’re healthy:
        </Paragraph>
        <Paragraph>
            <ExternalLink to = "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5310957/">Medical studies</ExternalLink> have confirmed that whole grains come with the following health benefits:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Reduced risk of type 2 diabetes</UnorderedListItem>
            <UnorderedListItem>Reduced risk and mortality from colorectal, gastric, and pancreatic cancers</UnorderedListItem>
            <UnorderedListItem>Reduced risk and mortality from cardiovascular disease</UnorderedListItem>
            <UnorderedListItem>Moderately positive effect on maintaining a healthy body weight</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Unlike refined grains, whole grains contain a lot of vitamin E, vitamin B, antioxidants, fiber, and a wide variety of minerals and phytonutrients.
        </Paragraph>
        <Paragraph>
            Next time you’re at the store, consider adding some of these grains to your pantry:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Amaranth</UnorderedListItem>
            <UnorderedListItem>Brown rice</UnorderedListItem>
            <UnorderedListItem>Bulgur</UnorderedListItem>
            <UnorderedListItem>Oats</UnorderedListItem>
            <UnorderedListItem>Spelt</UnorderedListItem>
            <UnorderedListItem>Quinoa</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Healthy fats
        </BlogHeading2>
        <Paragraph>
            Reasons why they’re healthy:
        </Paragraph>
        <Paragraph>
            What exactly are healthy or ‘good’ fats? 
        </Paragraph>
        <Paragraph>
            <ExternalLink to = "https://www.hsph.harvard.edu/nutritionsource/what-should-you-eat/fats-and-cholesterol/types-of-fat/">Healthy fat</ExternalLink> is unsaturated fat, more specifically monounsaturated and polyunsaturated fat. Its benefits are abundant: 
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Reduced inflammation in the body</UnorderedListItem>
            <UnorderedListItem>Lowered ‘bad’ cholesterol levels</UnorderedListItem>
            <UnorderedListItem>Increased ‘good’ cholesterol levels</UnorderedListItem>
            <UnorderedListItem>Stabilized heart rhythm</UnorderedListItem>
            <UnorderedListItem>Lowered risk of heart disease and stroke</UnorderedListItem>
            <UnorderedListItem>Improved cell and brain health, and more</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Unhealthy fats or ‘bad’ fats are saturated fats. Saturated fat actually brings an increased risk of diseases such as type 2 diabetes and coronary heart disease. Unlike healthy fat, it also increases inflammation in the body.
        </Paragraph>
        <Paragraph>
            Reducing your overall intake of fats is highly recommended by experts. However, it is not advisable to eliminate fats from your diet altogether - they do have an important role in the body. 
        </Paragraph>
        <Paragraph>
            Fats allow us to absorb certain vitamins from our food, such as vitamin A, vitamin D, vitamin E, and vitamin K. They are also the only source of essential fatty acids - substances that the body cannot produce on its own.
        </Paragraph>
        <Paragraph>
            <ExternalLink to = "https://www.medicalnewstoday.com/articles/essential-fatty-acids">Essential fatty acids</ExternalLink> are vital for the proper function of the immune system, nervous system, and certain hormones.
        </Paragraph>
        <Paragraph>
            Replacing unhealthy fats with healthy fats in your meal planning is crucial for a healthy diet. You can achieve this with the following pantry foods:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Avocado oil</UnorderedListItem>
            <UnorderedListItem>Olive oil (especially extra virgin olive oil)</UnorderedListItem>
            <UnorderedListItem>Safflower oil</UnorderedListItem>
            <UnorderedListItem>Sesame oil</UnorderedListItem>
            <UnorderedListItem>Canned fish, such as canned tuna and canned salmon</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            Contrary to popular belief, coconut oil is mostly made up of saturated fat, meaning it is not the healthiest option.
        </Paragraph>
        <BlockQuote>
            <b>PRO TIP:</b> Add all of these healthy pantry essentials to a <InternalLink to="/#download-section">grocery shopping list app</InternalLink> so you don’t forget them next time you’re at the store.
        </BlockQuote>
        <BlogHeading2>
            Takeaway
        </BlogHeading2>
        <Paragraph>
            A well-balanced healthy diet is chock full of nutritious food groups such as fruits and vegetables, legumes, nuts and seeds, and healthy fats. Animal products do have a place in it, but in limited amounts.
        </Paragraph>
        <Paragraph>
            At the same time, keep in mind that food is more than just fuel for the body. It also plays an important role in celebrations, social gatherings, cultural events, and more. It represents a way to bring people together and help them bond over delicious flavors that everyone knows and loves.
        </Paragraph>
        <Paragraph>
            So, while you should eat healthily the majority of the time, don’t hesitate to indulge in a portion of burger and fries or a piece of cake now and then. A healthy diet does not mean being 100% on point 100% of the time. It simply means that you are eating a wide range of foods and meals, most of which are highly nutritious.
        </Paragraph>
        <Paragraph>
            Here's a <InternalLink to="/plant-based-diet-shopping-list/">plant-based diet shopping list</InternalLink> to help you make healthier choices when building your pantry.
        </Paragraph>
        <Paragraph>
            Download our <InternalLink to="/#download-section">FREE shopping list app</InternalLink> to start stocking up on healthy staples on your next shopping trip!
        </Paragraph>
    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;